import { useEffect } from "react";
import layoutStore from "stores/layout.store";
import { environment } from "utils/config/environment";

const OPTIONAL_CONSENTS = ["C0002", "C0003", "C0004"];

const useDataLayerAfterOneTrustLoad = () => {
  const checkOneTrustLoaded = () => {
    let timeoutId;

    const isOptionalConsentGiven = (consents: string[]) => {
      return consents.length > 0 && consents.some((consentTag) => OPTIONAL_CONSENTS.includes(consentTag));
    };

    const rewriteEventsToTargetDataLayer = () => {
      if (window.tempDataLayer?.length > 0) {
        window.dataLayer = window.dataLayer || [];
        window.tempDataLayer.forEach((dataLayerEvent) => {
          window.dataLayer.push(dataLayerEvent);
        });
      }
      layoutStore.setIfTrackingAllowed(true);
      window.tempDataLayer = [];
    };

    const waitUntilCookieScriptLoaded = () => {
      if (window?.OneTrust || environment.isApplicationRunningInNodeModeDevelopment()) {
        if (window?.OptanonActiveGroups) {
          const consentList = window.OptanonActiveGroups.split(",");
          if (isOptionalConsentGiven(consentList)) {
            rewriteEventsToTargetDataLayer();
            return;
          }
        }
        if (window.OneTrust?.OnConsentChanged) {
          window.OneTrust.OnConsentChanged((data) => {
            if (data?.detail && Array.isArray(data.detail)) {
              if (isOptionalConsentGiven(data.detail)) {
                rewriteEventsToTargetDataLayer();
              }
            }
          });

          return;
        }
      }
      layoutStore.setIfTrackingAllowed(false);
      timeoutId = setTimeout(waitUntilCookieScriptLoaded, 100);
    };
    waitUntilCookieScriptLoaded();

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  };

  useEffect(() => {
    const cleanup = checkOneTrustLoaded();
    return cleanup;
  }, []);

  return null;
};

export { useDataLayerAfterOneTrustLoad };
