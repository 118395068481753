import layoutStore from "stores/layout.store";
import { environment } from "utils/config/environment";

declare global {
  interface Window {
    dataLayer: any;
    OneTrust: any;
    OptanonActiveGroups: string;
    tempDataLayer: any;
  }
}

const pushToDataLayer = (data: any) => {
  if (window) {
    if (layoutStore.isTrackingAllowed || environment.isApplicationRunningInNodeModeDevelopment()) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push(data);
    } else {
      window.tempDataLayer = window.tempDataLayer || [];
      window.tempDataLayer.push(data);
    }
  }
};

export { pushToDataLayer };
