import { packagesNaming } from "domain/Preview/ComposePreview/Packages/utils/packages-naming";
import { RecordCheckTrackingData } from "./dataLayerECommerceEvents.types";
import {
  VinregSearchType,
  type DataLayerCalculation,
  type DataLayerPageView,
  type DataLayerPageViewExternal,
  type DataLayerPaymentErrorExternal,
} from "./dataLayerEvents.types";
import { dataLayerUtil } from "./utils/dataLayerUtil";
import { pushToDataLayer } from "./utils/pushToDataLayer";

const submitPageViewEvent = ({
  pageReferrer,
  contentGroup,
  pageLanguage,
  vinregType,
  vinRecords,
  reportCombination,
  vinregSearchType,
}: DataLayerPageView): void => {
  const eventParameters: DataLayerPageViewExternal = {
    event: "page_view",
    page_location: window.location.href,
    page_referrer: pageReferrer,
    content_group: contentGroup,
    page_language: pageLanguage,
    vin_type: vinregType,
    vin_records: vinRecords,
    report_combination: reportCombination,
    vin_method: vinregSearchType,
  };
  pushToDataLayer(eventParameters);
};

const submitBolloCalculationEvent = ({ event, isVinSearch, errorMessage }: DataLayerCalculation): void => {
  const eventParameters = {
    event,
    calculation_type: isVinSearch ? "with_vin" : "without_vin",
    ...(errorMessage && { error_message: errorMessage }),
  };
  pushToDataLayer(eventParameters);
};

const submitSwitchBolloCalculatorTab = (eventWithVin: boolean): void => {
  const eventParameters = {
    event: "click_calculation_tab",
    click_text: eventWithVin ? "with_vin" : "without_vin",
  };
  pushToDataLayer(eventParameters);
};

const submitViewSampleReportEvent = (): void => {
  const eventParameters = {
    event: "view_sample_report",
  };
  pushToDataLayer(eventParameters);
};

const submitInputVoucherSuccessEvent = (voucherCode: string, voucherDiscountPercent: number): void => {
  const eventParameters = {
    event: "input_voucher_success",
    coupon: `${voucherCode} | ${voucherDiscountPercent}%`,
  };
  pushToDataLayer(eventParameters);
};

const submitInputVoucherErrorEvent = (voucherCode: string, voucherDiscountPercent: number): void => {
  const eventParameters = {
    event: "input_voucher_error",
    coupon: `${voucherCode} | ${voucherDiscountPercent}%`,
  };
  pushToDataLayer(eventParameters);
};

const vinSearchErrorEvent = (vinregSearchType?: string): void => {
  const eventParameters = {
    event: "vin_search_error",
    vin_method: vinregSearchType ?? VinregSearchType.MANUALLY,
  };
  pushToDataLayer(eventParameters);
};

const vinSearchSuccessEvent = (
  recordCheckTrackingData: RecordCheckTrackingData = {},
  vinregSearchType?: VinregSearchType
): void => {
  const { reportDataTotal = undefined, vinreg = undefined, reportCombination = undefined } = recordCheckTrackingData;

  const eventParameters = {
    event: "vin_search_success",
    vin_method: vinregSearchType ?? VinregSearchType.MANUALLY,
    vin_records: reportDataTotal,
    vin_type: dataLayerUtil.getVinregType(vinreg),
    report_combination: reportCombination,
  };
  pushToDataLayer(eventParameters);
};

const confirmRadioMissingEvent = (): void => {
  const eventParameters = {
    event: "confirm_radio_missing",
  };
  pushToDataLayer(eventParameters);
};

const submitPaymentErrorEvent = (paymentType: string, packageName: string, errorMessage: string): void => {
  const eventParameters: DataLayerPaymentErrorExternal = {
    event: "payment_error_viewed",
    payment_type: paymentType,
    package_name: packagesNaming.resolvePackageNameForTracking(packageName),
    error_message: errorMessage.substring(0, 100),
  };
  pushToDataLayer(eventParameters);
};

const submitButtonRedirectToPackagesEvent = (): void => {
  const eventParameters = {
    event: "click_select_package",
  };
  pushToDataLayer(eventParameters);
};

export const dataLayerEvents = {
  submitPageViewEvent,
  submitBolloCalculationEvent,
  submitViewSampleReportEvent,
  vinSearchSuccessEvent,
  vinSearchErrorEvent,
  confirmRadioMissingEvent,
  submitInputVoucherSuccessEvent,
  submitInputVoucherErrorEvent,
  submitSwitchBolloCalculatorTab,
  submitPaymentErrorEvent,
  submitButtonRedirectToPackagesEvent,
};
