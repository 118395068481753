import { RecordCheckData, VinregType } from "../dataLayerEvents.types";

const getVinregType = (vinreg?: string): VinregType => {
  if (!vinreg) {
    return undefined;
  }
  return vinreg.length < 10 ? VinregType.LICENSE_PLATE : VinregType.VIN;
};

const getRecordCheckData = (recordCheckStore): RecordCheckData => {
  const vinreg = recordCheckStore.isEncrypted ? recordCheckStore.encryptedQuery : recordCheckStore.query;
  return {
    vinregType: getVinregType(vinreg),
    vinRecords: recordCheckStore.reportDataTotal,
    reportCombination: recordCheckStore.reportCombination,
    vinregSearchType: recordCheckStore.vinregSearchType,
  };
};

export const dataLayerUtil = {
  getVinregType,
  getRecordCheckData,
};
