import { PackageVariant } from "@carfax/web-ui/components/PricingCard/components/IconPackage/IconPackage.types";

export enum B2CPackages {
  single = "single",
  medium = "medium",
  large = "large",
}

export enum B2BPackage {
  b2bSmall = "b2bSmall",
  b2bMedium = "b2bMedium",
  b2bLarge = "b2bLarge",
}

export enum PartnerPackages {
  SUBITO = "subito",
  ONE_CREDIT = "oneCredit",
}

export enum PromotionPackages {
  TWO_CREDITS = "twoCredits",
}

export enum PackagesNameTracking {
  Single = "starter",
  Advanced = "advanced",
  Professional = "professional",
  B2BSmall = "b2bSmall",
  B2BMedium = "b2bMedium",
  B2BLarge = "b2bLarge",
}

export type ProductNameTracking = PackagesNameTracking | PromotionPackages | PartnerPackages;

type B2BPackages = string;

const resolvePackageName = (oldPackageName: B2CPackages | B2BPackages): PackageVariant => {
  const packagesMapping = {
    single: PackageVariant.Single,
    medium: PackageVariant.Advanced,
    large: PackageVariant.Professional,
    b2bSmall: PackageVariant.B2BSmall,
    b2bMedium: PackageVariant.B2BMedium,
    b2bLarge: PackageVariant.B2BLarge,
  };
  return packagesMapping[oldPackageName] || oldPackageName;
};

const resolvePackageNameForTracking = (oldPackageName: B2CPackages | B2BPackages): PackagesNameTracking => {
  const packagesMapping = {
    single: PackagesNameTracking.Single,
    medium: PackagesNameTracking.Advanced,
    large: PackagesNameTracking.Professional,
    b2bSmall: PackagesNameTracking.B2BSmall,
    b2bMedium: PackagesNameTracking.B2BMedium,
    b2bLarge: PackagesNameTracking.B2BLarge,
  };
  return packagesMapping[oldPackageName] || oldPackageName;
};

const bestsellersId: Array<B2CPackages | B2BPackages> = [B2CPackages.medium, B2BPackage.b2bLarge];

const isB2BPackage = (packageName: B2CPackages | B2BPackages): packageName is B2BPackages => {
  return [B2BPackage.b2bSmall, B2BPackage.b2bMedium, B2BPackage.b2bLarge].includes(packageName as B2BPackage);
};

export const packagesNaming = {
  resolvePackageName,
  resolvePackageNameForTracking,
  bestsellersId,
  isB2BPackage,
};
